<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'App',
    metaInfo: {
      meta: [
        { name: 'robots', content: process.env.NUXT_ENV_NOFOLLOW || 'noindex, nofollow' },
      ]
    },
  }
</script>
<style src="./assets/style.css"></style>
<!-- <style src="./assets/tailwind.css"></style> -->

<style>
@import url(./assets/tailwind.css);

#app {
  font-family: "Montserrat", "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700,700i&display=swap');
</style>
